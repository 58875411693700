import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import SEO from 'structure/seo'
import Layout from 'structure/layout'

import { 
  Header, 
  Investors,
  SayHello,
  HeaderInternal,
} from 'partials'

import { 
  media,
  SectionHeader,
  Text,
} from 'ui'

const About = () => {
  return (
    <Layout>
      <SEO
        url='/about'
      />

      <Header onlyHead />

      <HeaderInternal
        title='Meet Us'
        description='We want to break the "let it brake" software culture. We see no more business or technical reasons for skipping the good practices of the clean and maintainable code base, scalable architecture, decoupled teams strategies, and authentic agile practices.'
      />
      
      <Slideshow />
      
      <CoreSection bottom>
        <SectionHeader
          pretitle='Core values'
          title='Excellence'
          description='We aim high, and we want to evolve every day, so it is mandatory to learn continuously and try new innovative ideas with strong discipline. We value those who give their best in their jobs and genuinely love what they do. When you love what you do, you excel.'
        />

        <CoreValues>
          <CoreItem>
            <CoreTitle>Not hierarchical</CoreTitle>
            <CoreDescription>
              We will be proactive, and you will not wait for someone else to solve a problem, as we are all responsible for the pursuit of excellence. That's why everyone in our team should have an opinion.
            </CoreDescription>
          </CoreItem>
          <CoreItem>
            <CoreTitle>Detail oriented</CoreTitle>
            <CoreDescription>
              When there are two options for accomplishing something, one satisfying and another exceptional, we will choose the latter, even if it means working harder than expected.
            </CoreDescription>
          </CoreItem>
          <CoreItem>
            <CoreTitle>Challenge lovers</CoreTitle>
            <CoreDescription>
              If our team believes and holds a goal that we need to reach a particular stage of a project, we will do our best to achieve it, rather than making excuses.
            </CoreDescription>
          </CoreItem>
        </CoreValues>
      </CoreSection>

      <Investors />

      <CoreSection top bottom>
        <SectionHeader
          pretitle='Core values'
          title='Teamwork'
          description='When we think of our mission – help innovators bring their ideas to life – we always remember that we need great people working together towards a common goal. We see the journey to accomplish ambitious goals as important as the results itself.'
        />

        <CoreValues>
          <CoreItem>
            <CoreTitle>The team thing</CoreTitle>
            <CoreDescription>
              We value gathering as a team, including our clients and users. We are constantly seeking feedback and evolving. So, we come to team gatherings eager to share and contribute to others.
            </CoreDescription>
          </CoreItem>
          <CoreItem>
            <CoreTitle>People-oriented</CoreTitle>
            <CoreDescription>
              We are respectful and empathetic to each other. We love that “team thing” and diversity. Maybe we are not always extroverted, but we just like people by heart. Doing great work together is better than a one-person show.
            </CoreDescription>
          </CoreItem>
          <CoreItem>
            <CoreTitle>Transparent</CoreTitle>
            <CoreDescription>
              We don't like any sort of hidden agenda. We prefer to be authentic, pragmatic, honest, and share our thoughts upfront, avoiding creating any misalignment among our peers.
            </CoreDescription>
          </CoreItem>
        </CoreValues>
      </CoreSection>

      <SectionEmpty />

      <CoreSection top>
        <SectionHeader
          pretitle='Core values'
          title='Learning'
          description='Our job has a high level of uncertainty and complexity, usually combining expertise from people from different backgrounds, requiring a lot of experience to get the right job done most effectively. Our knowledge is always expired by default because of the fast-paced innovation happening around the globe. That`s why we need to learn. Fast.'
        />

        <CoreValues>
          <CoreItem>
            <CoreTitle>Methodical</CoreTitle>
            <CoreDescription>
              We always search for the best method to do things. Questioning if we are doing our jobs in the most effective process is natural, and feedback is interpreted as natural shortcuts for improvement.
            </CoreDescription>
          </CoreItem>
          <CoreItem>
            <CoreTitle>Share</CoreTitle>
            <CoreDescription>
              We contribute proactively to the company and our peers. Whether sharing knowledge internally or externally, we see the value of teaching others as the best learning tool we could have.
            </CoreDescription>
          </CoreItem>
          <CoreItem>
            <CoreTitle>Early adopters</CoreTitle>
            <CoreDescription>
              When we are not learning something new, we get bored. We ask for new challenges and try to explore new stuff all the time. We are committed to bringing new ideas to our work every day.
            </CoreDescription>
          </CoreItem>
        </CoreValues>
      </CoreSection>

      <SayHello />
    </Layout>
  )
}

const SectionEmpty = styled.section`
  padding: 10rem 0;
  background: #333;
`

const CoreSection = styled.section`
  position: relative;
  padding: 10rem 0 8rem;
  --bar-size: 2px;
  --bar-color: #ddd;

  ${({ top }) => top && css`
    &:before {
      content: '';
      display: block;
      width: var(--bar-size);
      height: 8rem;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: calc(-1 * var(--bar-size) / 2);
      background-image: linear-gradient(0deg, hsla(0, 0%, 100%, 0), var(--bar-color));
    }
  `}

  ${({ bottom }) => bottom && css`
    &:after {
      content: '';
      display: block;
      width: var(--bar-size);
      height: 10rem;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: calc(-1 * var(--bar-size) / 2);
      background-image: linear-gradient(0deg, hsla(0, 0%, 100%, 0), var(--bar-color));
      transform: rotate(180deg);
    }
  `}

  ${media.lessThan('mediumDesktop')`
    padding: 5rem var(--spacing-medium) 4rem;
  `}
`

const CoreValues = styled.ul`
  padding: 8rem 0;
  margin: 0 auto;
  list-style: none;
  max-width: var(--size-inner);
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${media.lessThan('phone')`
    overflow: auto;
  `}
`

const CoreItem = styled.li`
  flex-basis: 30%;
  text-align: center;

  ${media.lessThan('phone')`
    display: block;
    min-width: 30rem;
    flex-basis: 50%;
    padding: 0 2rem 0 0;
  `}
`

const CoreTitle = styled.h3`
  font-size: 2.6rem;
  color: var(--color-primary);
  font-family: var(--font-title);
  padding: .5rem 0;
  margin: 0;

  &:after {
    content: '';
    display: block;
    margin: 1rem auto .5rem;
    width: 2rem;
    height: 2px;
    background: var(--color-accent);
  }
`

const CoreDescription = styled(Text)`
`

const slide = keyframes`
  to {
    background-position: -3473px 0;
  }
`

const Slideshow = styled.section`
  height: 34rem;
  background: url('/images/about/slideshow.jpg') #f9f9f9;
  background-size: cover;
  background-repeat: repeat-x;
  animation: ${slide} 300s linear infinite;

  ${media.lessThan('desktop')`
    height: 30rem;
  `}

  ${media.lessThan('phone')`
    height: 15rem;
  `}
`

export default About
